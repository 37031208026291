import axiosClient from './axiosClient';
import { useQuery, useMutation } from 'react-query';


export const imageApi = {
  getImage(params){
    return axiosClient.get(`/photo-library?page=${params.page}&page_size=${params.page_size}`);
  },
  getImageDetail(id){
    return axiosClient.get(`/admin/photo-library/${id}`);
  },
  create(data) {
    return axiosClient.post('/admin/photo-library', data);
  },
  update(data) {
    return axiosClient.patch(`/admin/photo-library/${data.id}`, data.data);
  },
  delete(data) {
    return axiosClient.delete(`/admin/photo-library/delete/multiple`,data);
  },
};

export const useImage = ({ params , options } = {}) => {
  return useQuery({
      queryKey: ['image', params],
      queryFn: () => imageApi.getImage(params),
  })
}

  export const useImageDetail = ({ id, options } = {}) => {
    return useQuery({
      queryKey: ['image-detail', id],
      queryFn: () => imageApi.getImageDetail(id),
      ...options,
    });
  };

  export const useCreateImage = () => {
    return useMutation(imageApi.create);
  };

  export const useUpdateImage = () => {
    return useMutation(imageApi.update);
  };

  export const useDeleteImage = () => {
    return useMutation(imageApi.delete);
  };