import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Grid, Drawer } from 'antd';

import { ROLE } from 'constants/roles';
import { PATH_NAME } from 'constants/routes';
import {
  StyledLayout,
  StyledLayoutContentWrapper,
  StyledLayoutContent,
} from 'styles/overrides';
import {
  PieChartIcon,
  InboxIcon,
  LayoutIcon,
  PeopleIcon,
  LogIcon,
  SettingIcon,
  HomeIcon,
} from 'assets/icons';
import { getCurrentUser } from 'store/slices/authSlice';
import { Sider } from './components/Sider';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { filteredMenuItemsByCurrentRole } from './utils/handleMenuItems';

const MENU_ITEMS = [
  {
    url: PATH_NAME.GUIDE,
    key: 'guide',
    icon: <PieChartIcon />,
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
  },
  {
    url: PATH_NAME.DASHBOARD,
    key: 'dashboard',
    icon: <PieChartIcon />,
    
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
  },
  {
    url: PATH_NAME.ORDERS,
    key: 'orders',
    icon: <InboxIcon />,
    
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN, ROLE.VIEWER],
  },
  {
    url: PATH_NAME.PRODUCTS,
    key: 'products',
    icon: <LayoutIcon />,
    
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
  },
  {
    url: PATH_NAME.CUSTOMERS,
    key: 'customers',
    icon: <PeopleIcon />,
    
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
  },
  {
    url: PATH_NAME.SHOPS,
    key: 'shops',
    icon: <HomeIcon />,
    
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
  },
  {
    url: PATH_NAME.USERS,
    key: 'users',
    icon: <PeopleIcon />,
    
    roles: [ROLE.SUPPER_ADMIN],
  },
  {
    url: PATH_NAME.LOGS,
    key: 'logs',
    icon: <LogIcon />,
    
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
  },
  {
    url: PATH_NAME.SETTINGS,
    key: 'settings',
    icon: <SettingIcon />,
    
    roles: [ROLE.SUPPER_ADMIN, ROLE.ADMIN],
  },
];
// function getItem(label, key, icon, children, type) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//     type,
//   };
// }
// const MENU_ITEMS = [
//   getItem('Option 1', '1', ),
//   getItem('Option 2', '2', ),
//   getItem('Option 3', '3', ),
//   getItem('Navigation One', 'sub1', null, [
//     getItem('Option 5', '5'),
//     getItem('Option 6', '6'),
//     getItem('Option 7', '7'),
//     getItem('Option 8', '8'),
//   ]),
//   getItem('Navigation Two', 'sub2', null, [
//     getItem('Option 9', '9'),
//     getItem('Option 10', '10'),
//     getItem('Submenu', 'sub3', null, [getItem('Option 11', '11'), getItem('Option 12', '12')]),
//   ]),
// ];
export default function DashboardLayout() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const screens = Grid.useBreakpoint();

  const filteredMenuItems = useMemo(
    () => filteredMenuItemsByCurrentRole(MENU_ITEMS, currentUser?.role?.name),
    [currentUser],
  );

  const handleToggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <>
      <StyledLayout>
        {screens.lg && (
          <Sider collapsed={isCollapsed} items={MENU_ITEMS} />
        )}

        <StyledLayoutContentWrapper collapsed={isCollapsed.toString()}>
          <Header onCollapse={handleToggleCollapse} />
          <StyledLayoutContent>
            <Outlet />
          </StyledLayoutContent>
          <Footer />
        </StyledLayoutContentWrapper>
      </StyledLayout>

      {!screens.lg && (
        <Drawer
          open={isCollapsed}
          closable={false}
          onClose={() => setIsCollapsed(false)}
          bodyStyle={{ padding: 0 }}
          contentWrapperStyle={{ width: 'auto' }}
          placement="left"
        >
          <Sider items={filteredMenuItems} />
        </Drawer>
      )}
    </>
  );
}
