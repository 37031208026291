import { Button, Form, Input, Modal } from 'antd';
import { useCreateVision } from 'api/visionApi';
import React, { memo, useState } from 'react';
import { toast } from 'react-toastify';

function CreateModal({ isModalOpen, setIsModalOpen, refetch }) {
  const [name, setName] = useState();
  const [link, setLink] = useState();
  const [click,setClick] = useState(false);

  const useCreateVisionMutation = useCreateVision();

  const handleOk = () => {
    setClick(true)
    const data = {
      name,
      link,
    };

    useCreateVisionMutation.mutate(data, {
      onSuccess: (response) => {
        toast.success('Tạo Mới Thành Công');
        refetch();
        setName('');
        setLink('');
        setIsModalOpen(false);
        setClick(false)
      },
      onError: (error) => {
        toast.error('Tạo Mới Thất Bại');
      },
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setName('');
    setLink('');
    setClick(false)
  };

  return (
    <>
      <Modal
        title="TẠO MỚI"
        className="mt-[150px] "
        onOk={handleOk}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <div>
          <p>Tên :</p>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="h-10"
          />
          {click && !name && <div className="text-red-500">Vui lòng nhập tên *</div>}
        </div>

        <div className="pt-3">
          <p>Đường dẫn :</p>
          <Input
            value={link}
            onChange={(e) => setLink(e.target.value)}
            className="h-10"
          />
          {click && !link && <div className="text-red-500">Vui lòng nhập đường dẫn *</div>}

        </div>
      </Modal>
    </>
  );
}

export default memo(CreateModal);
