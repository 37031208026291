import { Button, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useCreateVideo } from 'api/videoLibraryApi';
import NameForm from 'components/formItem/NameForm';
import SaveButton from 'components/button/SaveButton';

function CreateVideoLibrary() {
  const [disableButton, setDisableButton] = useState(true);
  const navigate = useNavigate();
  const useCreateVideoMutation = useCreateVideo();

  const onFinish = (values) => {
    setDisableButton(false);
    const data = {
      ...values,
    };
    // console.log('Success : ', data);
    useCreateVideoMutation.mutate(
      { ...data },
      {
        onSuccess: (response) => {
          // console.log(response);
          toast.success('Thêm Video Thành Công');
          navigate(-1);
        },
        onError: (error) => {
          // console.log('error : ', error);
          toast.error('Thêm Video Thất Bại');
          setDisableButton(true);
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <div className="title text-blue-500">
        TẠO MỚI BÀI VIẾT
      </div>
      <div className="mt-[50px]">
        <Form
          size="large"
          name="wrap"
          labelCol={{
            flex: '120px',
          }}
          labelWrap
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <NameForm label="Tiêu đề Video : " name="title_vi" />

          <NameForm label="Liên kết : " name="link" />

          <div className='ml-5'><SaveButton disable={disableButton} /></div>
        </Form>
      </div>
    </div>
  );
}
export default CreateVideoLibrary;
