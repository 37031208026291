import axiosClient from './axiosClient';
import { useQuery, useMutation } from 'react-query';


export const VisionApi = {
  getMenu(){
    return axiosClient.get(`/vision/menus`);
  },
  getImage(){
    return axiosClient.get(`/vision/file`);
  },
  getMenuDetail(id){
    return axiosClient.get(`/admin/vision/menus/${id}`);
  },
  create(data) {
    return axiosClient.post('/admin/vision/menus', data);
  },
  updateImage(data) {
    return axiosClient.patch(`/admin/vision/file`, data);
  },
  updateMenu(data) {
    return axiosClient.patch(`/admin/vision/menus/${data.id}`, data.data);
  },
  delete(data) {
    return axiosClient.delete(`/admin/vision/menus/delete/multiple`,data);
  },
};

export const useMenuVision = () => {
  return useQuery({
      queryKey: ['Menu'],
      queryFn: () => VisionApi.getMenu(),
  })
}

export const useImageVision = () => {
  return useQuery({
      queryKey: ['ImageVision'],
      queryFn: () => VisionApi.getImage(),
  })
}

  export const useVisionDetail = ({ id, options } = {}) => {
    return useQuery({
      queryKey: ['Vision-detail', id],
      queryFn: () => VisionApi.getMenuDetail(id),
      ...options,
    });
  };

  export const useCreateVision = () => {
    return useMutation(VisionApi.create);
  };

  export const useUpdateImageVision = () => {
    return useMutation(VisionApi.updateImage);
  };

  export const useUpdateMenuVision = () => {
    return useMutation(VisionApi.updateMenu);
  };

  export const useDeleteVision = () => {
    return useMutation(VisionApi.delete);
  };