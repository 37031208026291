import { Button } from 'antd';
import React, { memo } from 'react';

function SaveButton(disable) {

  return (
    <>
      <Button
        htmlType="submit"
        disabled={!disable}
        className="bg-blue-500 text-white rounded ml-[100px] "
      >
        LƯU
      </Button>
    </>
  );
}

export default memo(SaveButton);
