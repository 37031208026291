import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Table } from 'antd';

import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import CreateNewButton from 'components/button/CreateNewButton';
import DeleteButton from 'components/button/DeleteButton';
import EditButton from 'components/button/EditButton';
import { formatTime } from 'utils';
import { useDeleteService, useServiceList } from 'api/serviceApi';

const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
    key: 'stt',
    width: '60px',
  },
  {
    title: 'Tên ',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    render: (value) => (
      <Link
        to={`/dich-vu-y-te/edit/${value?.slug_vi}`}
        className="text-blue-500"
      >
        {value?.name_vi}
      </Link>
    ),
  },
  {
    title: 'Ngày Cập Nhật',
    dataIndex: 'update_at',
    key: 'update_at',
    align: 'center',
    render: (value) => formatTime(value, 'DD/MM/YYYY'),
  },
  {
    title: 'Ngày Tạo',
    dataIndex: 'create_at',
    key: 'create_at',
    align: 'center',
    render: (value) => formatTime(value, 'DD/MM/YYYY'),
  },
];

function Service() {
  const [slugEdit, setSlugEdit] = useState();
  const [selected, setSelected] = useState([]);
  let { slug } = useParams();
  const [search, setSearch] = useCustomSearchParams();
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  const { data, refetch } = useServiceList({ slug: slug, ...argument });
  const deleteGuideMutation = useDeleteService();

  const dataSource = [];
  data?.data?.map((item, index) => {
    dataSource.push({
      id: item.id,
      key: index,
      stt: index + 1,
      name: item,
      update_at: item.updated_at,
      create_at: item.created_at,
    });
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSlugEdit(selectedRows[0]?.name?.slug_vi);
      setSelected(selectedRowKeys);
    },
  };

  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });
  const renderExtra = <></>;
  return (
    <div>
      <div className="my-5 flex">
        <CreateNewButton slug={`/dich-vu-y-te/create/${slug}`} />
        <EditButton
          slug={`/dich-vu-y-te/edit/${slugEdit}`}
          selected={selected}
        />
        <DeleteButton
          selected={selected}
          refetch={refetch}
          deleteMutation={deleteGuideMutation}
        />
      </div>

      <Table
        bordered
        rowKey="id"
        rowSelection={{
          ...rowSelection,
        }}
        dataSource={dataSource}
        columns={columns}
        onChange={handleChange}
        extra={renderExtra}
        pagination={{
          total: data?.meta?.total ?? 0,
          className: 'mr-5',
        }}
      />
    </div>
  );
}

export default Service;
