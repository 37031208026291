import { Button, Form, Input, Modal } from 'antd';
import { useUpdateMenuVision, useVisionDetail } from 'api/visionApi';
import NameForm from 'components/formItem/NameForm';
import React, { memo, useState } from 'react';
import { toast } from 'react-toastify';

function EditModal({ isModalOpen, setIsModalOpen, refetch, id }) {
  const [form] = Form.useForm();
  const useUpdateVisionMutation = useUpdateMenuVision();

  const {} = useVisionDetail({
    id: id,
    options: {
      onSuccess(res) {
        const data = res.data;
        form.setFieldsValue({
          name: data.name,
          link: data.link,
        });
      },
    },
  });

  const onFinish = (values) => {
    const data = {
      ...values,
    };
    useUpdateVisionMutation.mutate(
      { id, data },
      {
        onSuccess: (response) => {
          console.log('response',response)
          toast.success('Cập Nhật Thành Công');
          setIsModalOpen(false);
          refetch();
        },
        onError: (error) => {
          console.log('error',error)
          toast.error('Cập Nhật Thất Bại');
        },
      },
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Modal
        title="CHỈNH SỬA"
        className="mt-[150px] "
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <Form
          size="large"
          name="wrap"
          form={form}
          labelCol={{
            flex: '100px',
          }}
          labelWrap
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <NameForm label="Tên :" name="name" />

          <NameForm label="Đường dẫn :" name="link" />

          <div className="relative pb-6">
            <Button
              htmlType="submit"
              className="bg-blue-500 text-white hover:bg-white hover:text-blue-500 absolute right-0"
            >
              LƯU
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default memo(EditModal);
