import axiosClient from './axiosClient';
import { useQuery, useMutation } from 'react-query';

export const videoApi = {
  getVideo(params) {
    return axiosClient.get(
      `/video-library?page=${params.page}&page_size=${params.page_size}`,
    );
  },
  getVideoDetail(id) {
    return axiosClient.get(`/admin/video-library/${id}`);
  },
  create(data) {
    return axiosClient.post('/admin/video-library', data);
  },
  update(data) {
    return axiosClient.patch(`/admin/video-library/${data.id}`, data.data);
  },
  delete(data) {
    return axiosClient.delete(`/admin/video-library/delete/multiple`, data);
  },
};

export const useVideo = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['video', params],
    queryFn: () => videoApi.getVideo(params),
  });
};

export const useVideoDetail = ({ id, options } = {}) => {
  return useQuery({
    queryKey: ['video-detail', id],
    queryFn: () => videoApi.getVideoDetail(id),
    ...options,
  });
};

export const useCreateVideo = () => {
  return useMutation(videoApi.create);
};

export const useUpdateVideo = () => {
  return useMutation(videoApi.update);
};

export const useDeleteVideo = () => {
  return useMutation(videoApi.delete);
};
