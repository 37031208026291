import axiosClient from './axiosClient';
import { useQuery, useMutation } from 'react-query';

export const serviceApi = {
  getMenu(params){
    return axiosClient.get('/service', {params})
  },
  getService(slug){
    return axiosClient.get(`/service/${slug}`)
  },
  getServiceList(slug,params) {
    return axiosClient.get(`/service/list/${slug}?page=${params.page}&page_size=${params.page_size}`);
  },
  create(data) {
    return axiosClient.post('/admin/service', data);
  },
  updateService(data) {
    return axiosClient.patch(`/admin/service/${data.slug}`, data.data);
  },
  delete(data) {
    return axiosClient.delete(`/admin/service/delete/multiple`,data);
  },
};

export const useServiceMenu = ({params}) => {
  return useQuery({
      queryKey: ['service', params],
      queryFn: () => serviceApi.getMenu(params),

  })
}

  export const useServiceList = ({ slug,params, options } = {}) => {
    return useQuery({
      queryKey: ['service-list', slug,params],
      queryFn: () => serviceApi.getServiceList(slug,params),
      ...options,
    });
  };
  export const useService = ({ slug, options } = {}) => {
    return useQuery({
      queryKey: ['service-detail', slug],
      queryFn: () => serviceApi.getService(slug),
      ...options,
    });
  };

  export const useCreateService = () => {
    return useMutation(serviceApi.create);
  };

  export const useUpdateService = () => {
    return useMutation(serviceApi.updateService);
  };

  export const useDeleteService = () => {
    return useMutation(serviceApi.delete);
  };