import { Button } from 'antd'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'

function CreateNewButton({slug}) {

  return (
    <div>
        <Button className='bg-blue-500 text-white rounded'><Link to={slug} >THÊM MỚI</Link></Button>
    </div>
  )
}

export default memo(CreateNewButton)