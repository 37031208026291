import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import bgImg from 'assets/images/auth-bg.png';
import { Footer } from './components/Footer';

const StyledIndexLayout = styled.div`
  min-height: 100vh;
  width: 100%;
  padding: 32px 32px 0;
  background: url(${bgImg}) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;

  @media (max-width: 767px) {
    padding: 16px 16px 0;
  }
`;

export default function IndexLayout() {
  return (
    <StyledIndexLayout>
      <Outlet />

      <Footer />
    </StyledIndexLayout>
  );
}
