import { useIntl } from 'react-intl';
import { DEFAULT_PAGINATION } from 'constants/common';
import { FilterForm } from 'components/form';
import { StyledSpace, StyledFormItem } from 'styles/overrides';
import { normalizeSearchData, normalizeSubmitSearchData } from 'utils/common';
import { ShopSelector } from 'components/selector';

export const SettingFilterSearchForm = ({ onSubmit, initialValues }) => {
  const intl = useIntl();

  const handleSubmit = (values) => {
    onSubmit(
      normalizeSubmitSearchData({
        ...initialValues,
        ...values,
        page: DEFAULT_PAGINATION.PAGE,
      }),
    );
  };

  return (
    <FilterForm
      onFinish={handleSubmit}
      initialValues={normalizeSearchData(initialValues)}
      isShowResetBtn={false}
    >
      <StyledSpace size={16}>
        <StyledFormItem name="shopId">
          <ShopSelector
            placeholder={intl.formatMessage({
              id: 'common.placeholder.shopId',
            })}
            $customType="filter"
          />
        </StyledFormItem>
      </StyledSpace>
    </FilterForm>
  );
};
