import { Switch } from 'antd';
import React, { memo } from 'react';

function SwitchHome({ switchHome, setSwitchHome }) {
  return (
    <div className="ml-[100px] mb-5">
      <p className="mb-2">Hiện thị trên trang chủ : </p>
      <Switch
        checked={switchHome ? true : false }
        onChange={() => setSwitchHome(!switchHome)}
        className={
          switchHome ? 'bg-blue-500 duration-300' : 'bg-gray-500 duration-300'
        }
      />
    </div>
  );
}

export default memo(SwitchHome);
