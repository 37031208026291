import axiosClient from './axiosClient';
import { useQuery, useMutation } from 'react-query';

export const postApi = {
  getBanner() {
    return axiosClient.get('/home/banner');
  },
  getHomePost() {
    return axiosClient.get('/home/post');
  },
  getSearch() {
    return axiosClient.get('/home/search');
  },
  getPost(slug) {
    return axiosClient.get(`/post/${slug}`);
  },
  create(data) {
    return axiosClient.post('/admin/post', data);
  },
  update(data) {
    return axiosClient.patch(`/admin/post/${data.slug}`, data.data);
  },
  delete(data) {
    return axiosClient.delete(`/admin/post/delete/multiple`, data);
  },

  // action Api
  getMenuAction() {
    return axiosClient.get('/action');
  },
  getActionList(slug, params) {
    return axiosClient.get(`/action/list/${slug}?page=${params.page}&page_size=${params.page_size}`);
  },
  // ---

  // news Api
  getMenuNews() {
    return axiosClient.get('/news');
  },
  getNewsList(slug, params) {
    return axiosClient.get(`/news/list/${slug}?page=${params.page}&page_size=${params.page_size}`);
  },
  // ---
};
// action
export const useActionMenu = () => {
  return useQuery({
    queryKey: ['action-menu'],
    queryFn: () => postApi.getMenuAction(),
  });
};

export const useActionList = ({ slug, params, options } = {}) => {
  return useQuery({
    queryKey: ['action-list', slug, params],
    queryFn: () => postApi.getActionList(slug, params),
    ...options,
  });
};
// action ------- end

// news
export const useNewsMenu = () => {
  return useQuery({
    queryKey: ['news-menu'],
    queryFn: () => postApi.getMenuNews(),
  });
};

export const useNewsList = ({ slug, params, options } = {}) => {
  return useQuery({
    queryKey: ['news-list', slug, params],
    queryFn: () => postApi.getNewsList(slug, params),
    ...options,
  });
};

// news -------------- end
export const useHomeBanner = () => {
  return useQuery({
    queryKey: ['banner-home'],
    queryFn: () => postApi.getBanner(),
  });
};

export const useHomePost = () => {
  return useQuery({
    queryKey: ['post-home'],
    queryFn: () => postApi.getHomePost(),
  });
};

export const useSearch = () => {
  return useQuery({
    queryKey: ['search-home'],
    queryFn: () => postApi.getSearch(),
  });
};

export const usePost = ({ slug, options } = {}) => {
  return useQuery({
    queryKey: ['post-detail', slug],
    queryFn: () => postApi.getPost(slug),
    ...options,
  });
};

export const useCreatePost = () => {
  return useMutation(postApi.create);
};

export const useUpdatePost = () => {
  return useMutation(postApi.update);
};

export const useDeletePost = () => {
  return useMutation(postApi.delete);
};
