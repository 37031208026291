import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import bgImg from 'assets/images/auth-bg.png';
import { Footer } from './components/Footer';

const StyledAuthLayout = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${bgImg}) no-repeat center;
  background-size: cover;
  position: relative;
`;

const StyledAuthLayoutWrapper = styled.div`
  flex-grow: 1;
  padding: 32px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export default function AuthLayout() {
  return (
    <StyledAuthLayout>
      <StyledAuthLayoutWrapper>
        <Outlet />
      </StyledAuthLayoutWrapper>

      <Footer />
    </StyledAuthLayout>
  );
}
