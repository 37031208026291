import axiosClient from './axiosClient';
import { useQuery, useMutation } from 'react-query';

export const guideApi = {
  getMenu(params) {
    return axiosClient.get('/guide', { params });
  },
  getGuide(slug) {
    return axiosClient.get(`/guide/${slug}`);
  },
  getGuideList(slug, params) {
    return axiosClient.get(
      `/guide/list/${slug}?page=${params.page}&page_size=${params.page_size}`,
    );
  },
  create(data) {
    return axiosClient.post('/admin/guide', data);
  },
  updateGuide(data) {
    return axiosClient.patch(`/admin/guide/${data.slug}`, data.data);
  },
  delete(data) {
    return axiosClient.delete(`/admin/guide/delete/multiple`, data);
  },

  // price service /price-list
  getPriceService(params) {
    return axiosClient.get(
      `/price-list?page=${params.page}&page_size=${params.page_size}`,
    );
  },
  getDetailPrice(slug) {
    return axiosClient.get(`/price-list/${slug}`);
  },
  createPrice(data) {
    return axiosClient.post('/admin/price-list', data);
  },
  updatePrice(data) {
    return axiosClient.patch(`/admin/price-list/${data.slug}`, data.data);
  },
  deletePrice(data) {
    return axiosClient.delete(`/admin/price-list/delete/multiple`, data);
  },
};
export const usePriceService = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['price-serive', params],
    queryFn: () => guideApi.getPriceService(params),
  });
};
export const useDetailPrice = ({ slug, options } = {}) => {
  return useQuery({
    queryKey: ['price-detail', slug],
    queryFn: () => guideApi.getDetailPrice(slug),
    ...options,
  });
};

export const useCreatePrice = () => {
  return useMutation(guideApi.createPrice);
};

export const useUpdatePrice = () => {
  return useMutation(guideApi.updatePrice);
};

export const useDeletePrice = () => {
  return useMutation(guideApi.deletePrice);
};

export const useGuideMenu = ({ params }) => {
  return useQuery({
    queryKey: ['guide', params],
    queryFn: () => guideApi.getMenu(params),
  });
};

export const useGuideList = ({ slug, params, options } = {}) => {
  return useQuery({
    queryKey: ['guide-list', slug, params],
    queryFn: () => guideApi.getGuideList(slug, params),
    ...options,
  });
};
export const useGuide = ({ slug, options } = {}) => {
  return useQuery({
    queryKey: ['guide-detail', slug],
    queryFn: () => guideApi.getGuide(slug),
    ...options,
  });
};

export const useCreateGuide = () => {
  return useMutation(guideApi.create);
};

export const useUpdateGuide = () => {
  return useMutation(guideApi.updateGuide);
};

export const useDeleteGuide = () => {
  return useMutation(guideApi.delete);
};
