export const PATH_NAME = {
  ROOT: '/',
  ADMIN: '/admin',

  CUSTOMER_ORDER: '/orders',
  LOGIN: '/login',
  DASHBOARD: 'dashboard',
  ORDERS: 'customer-orders',
  PRODUCTS: 'products',
  CUSTOMERS: 'customers',
  USERS: 'users',
  LOGS: 'logs',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  USER: 'user',
  SHOPS: 'shops',

  GUIDE: 'huong-dan',
  INTRODUCE: 'gioi-thieu',
  SERVICE: 'dich-vu-y-te',
  NEWS: 'tin-tuc',
  ACTION: 'hoat-dong',
  PRICE_SERVICE: 'bang-gia-dich-vu',
  QA: 'hoi-dap',
  IMAGE_LIBRARY: 'thu-vien-anh',
  VIDEO_LIBRARY: 'thu-vien-video',
  VISION_MISSION : 'tam-nhin-su-menh'
};

export const PATH_NAME_ACTION = {
  EDIT: 'edit',
};
