import { Form, Input } from 'antd';
import React from 'react';

function NameForm({ label, name }) {
  return (
    <div>
      <Form.Item
        label={label}
        name={name}
        rules={[{ required: true, message: `Vui lòng nhập ${label} ` }]}
      >
        <Input allowClear/>
      </Form.Item>
    </div>
  );
}

export default NameForm;
