import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Popover, Table } from 'antd';

import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { formatTime } from 'utils';
import { useDeleteCategory, useDeleteHealth, useHealthAll } from 'api/healthApi';
import CreateNewCategory from './components/CreateNewCategory';
import EditCategory from './components/EditCategory';
import DeleteButton from 'components/button/DeleteButton';

function Health() {
  const [isCreateNewModal, setIsCreateNewModalModalOpen] = useState(false);
  const [isEditModal, setIsEditModalOpen] = useState(false);
  const showModal = () => {
    setIsCreateNewModalModalOpen(true);
  };
  const [slugEdit, setSlugEdit] = useState();
  const [selected, setSelected] = useState([]);
  let { slug } = useParams();
  const [search, setSearch] = useCustomSearchParams();
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  const { data, refetch } = useHealthAll({ slug: slug, ...argument });
  const deleteGuideMutation = useDeleteCategory();

  const dataSource = [];
  data?.data?.map((item, index) => {
    dataSource.push({
      id: item.id,
      key: index,
      stt: index + 1,
      name: item,
      editName: item,
    });
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //   setSlugEdit(selectedRows[0]?.name?.slug_vi);
      setSelected(selectedRowKeys);
    //   console.log(selectedRowKeys);
    },
  };

  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });
  const renderExtra = <></>;

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      key: 'stt',
      width: '60px',
    },
    {
      title: 'Tên ',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (value) => (
        <Link
          to={`/dich-vu-y-te/kham-suc-khoe/${value?.slug_vi}`}
          className="text-blue-500"
        >
          {value?.name_vi}
        </Link>
      ),
    },
    {
      dataIndex: 'editName',
      key: 'editName',
      align: 'center',
      render: (value) => (
        <Button
          className="button-yellow"
          onClick={() => {
            setIsEditModalOpen(true);
            setSlugEdit(value?.slug_vi);
          }}
        >
          Sửa
        </Button>
      ),
    },
  ];

  const content = (
    <div>
      <p className='text-red-500 font-medium'>BAO GỒM TẤT CẢ CÁC DỮ LIỆU LIÊN QUAN</p>
    </div>
  );
  return (
    <div>
      <CreateNewCategory
        isModalOpen={isCreateNewModal}
        setIsModalOpen={setIsCreateNewModalModalOpen}
        refetch={refetch}
      />

      <EditCategory
        isModalOpen={isEditModal}
        setIsModalOpen={setIsEditModalOpen}
        refetch={refetch}
        slug={slugEdit}
      />

      <div className="my-5 flex">
        <Button className="button-blue mr-3" onClick={showModal}>
          THÊM MỚI
        </Button>
        <Popover content={content} title="XÁC NHẬN XÓA" trigger="hover">
          <DeleteButton
            selected={selected}
            refetch={refetch}
            deleteMutation={deleteGuideMutation}
          />
          <div></div>
        </Popover>
      </div>

      <Table
        bordered
        rowKey="id"
        rowSelection={{
          ...rowSelection,
        }}
        dataSource={dataSource}
        columns={columns}
        onChange={handleChange}
        extra={renderExtra}
        pagination={{
          total: data?.meta?.total ?? 0,
          className: 'mr-5',
        }}
      />
    </div>
  );
}

export default Health;
