import { useMemo, useState } from 'react';
import { Spin } from 'antd';
import {
  useGetCustomerByPhone,
  useCheckOTP,
  useCreatePublicCustomer,
  useUpdatePublicCustomer,
} from 'api/customerApi';
import { CardWrapper, ContentWrapper } from 'components/layout';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { SearchPhoneForm } from './components/SearchPhoneForm';
import { ProfileForm } from './components/ProfileForm';
import { OTPModal } from 'components/OTP';
import { useSendOTP } from 'api/smsApi';
import { Notification } from 'components/Notification';
import { STORAGE_KEY } from '../../constants/common';

function Profile({ isVerifyOTP = false }) {
  const intl = useIntl();
  const getCustomerByPhoneMutation = useGetCustomerByPhone();
  const sendOTPMutation = useSendOTP();
  const checkOTPMutation = useCheckOTP();
  const createPublicCustomerMutation = useCreatePublicCustomer();
  const updatePublicCustomerMutation = useUpdatePublicCustomer();
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [searchedPhoneData, setSearchedPhoneData] = useState(null);

  const mutation = useMemo(
    () =>
      _.isEmpty(currentCustomer)
        ? createPublicCustomerMutation
        : updatePublicCustomerMutation,
    [
      currentCustomer,
      createPublicCustomerMutation,
      updatePublicCustomerMutation,
    ],
  );

  const handleSubmitSearchPhone = (values) => {
    if (!isVerifyOTP) return;

    setCurrentCustomer(null);
    setSearchedPhoneData(values);
    handleGetCustomerByPhone(values);
  };

  const handleSubmitData = ({ defaultAddress, ...values }) => {
    const data = { ...defaultAddress, ...values, ...searchedPhoneData };

    if (currentCustomer?.id) {
      data.id = currentCustomer?.id;
    }

    mutation.mutate(data, {
      onSuccess() {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.${data?.id ? 'updateSuccess' : 'createSuccess'}`,
          }),
        );

        setCurrentCustomer(null);
      },
      onError(error) {
        Notification(
          'error',
          error?.message || intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  const handleGetCustomerByPhone = ({ phone }) => {
    getCustomerByPhoneMutation.mutate(phone, {
      onSuccess(response) {
        setCurrentCustomer(response);
      },
      onError(error) {
        if (error?.status === 401 || error?.status === 403) {
          handleSendOTP(phone);
        } else {
          setCurrentCustomer({});
        }
      },
    });
  };

  const handleSendOTP = (phone) => {
    sendOTPMutation.mutate(
      { phone },
      {
        onSuccess() {
          setIsOpenModal(true);
        },
        onError() {
          Notification(
            'error',
            intl.formatMessage({ id: 'message.commonError' }),
          );
        },
      },
    );
  };

  const handleCheckOTP = (values) => {
    const data = { ...values, ...searchedPhoneData };

    checkOTPMutation.mutate(data, {
      onSuccess(response) {
        setIsOpenModal(false);
        localStorage.setItem(STORAGE_KEY.OTP_TOKEN, response?.accessToken);
        handleGetCustomerByPhone(data);
      },
      onError(error) {
        Notification(
          'error',
          error?.message || intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <>
      <CardWrapper
        style={{
          maxWidth: 900,
          margin: '0 auto',
          width: '100%',
          backgroundColor: 'var(--white)',
        }}
      >
        <Spin
          spinning={
            getCustomerByPhoneMutation.isLoading || sendOTPMutation.isLoading
          }
        >
          <ContentWrapper
            title={intl.formatMessage({
              id: 'views.profile.numberPhoneLookupTitle',
            })}
            hasBorder
          >
            <SearchPhoneForm onSubmit={handleSubmitSearchPhone} />
          </ContentWrapper>
        </Spin>
      </CardWrapper>

      {!getCustomerByPhoneMutation.isLoading && !_.isNil(currentCustomer) && (
        <CardWrapper
          style={{
            maxWidth: 900,
            margin: '0 auto',
            width: '100%',
            backgroundColor: 'var(--white)',
            marginTop: 32,
          }}
        >
          <ProfileForm
            initialValues={currentCustomer}
            onSubmit={handleSubmitData}
            isAddNew={_.isEmpty(currentCustomer)}
          />
        </CardWrapper>
      )}

      <OTPModal
        open={isOpenModal}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        onSubmit={handleCheckOTP}
        isLoading={checkOTPMutation.isLoading}
      />
    </>
  );
}

export default Profile;
