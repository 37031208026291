import { Button, Table } from 'antd';
import {
  useDeleteVision,
  useImageVision,
  useMenuVision,
  useUpdateImageVision,
} from 'api/visionApi';
import DeleteButton from 'components/button/DeleteButton';
import SaveButton from 'components/button/SaveButton';
import UploadImage from 'components/uploadImage/UploadImage';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CreateModal from './components/CreateModal';
import EditModal from './components/EditModal';

export default function Vision() {
  const [isModalCreate, setIsModalCreate] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const { data: ImageVision } = useImageVision();
  const [selected, setSelected] = useState([]);
  const [imageId, setImageId] = useState();
  const [imageUrl, setImageUrl] = useState();
  console.log(imageUrl);
  const [id, setId] = useState();

  const updateImageVisionMutation = useUpdateImageVision();
  const deleteVisionMenuMutation = useDeleteVision();
  const { data, refetch } = useMenuVision();

  const dataSource = [];
  data?.data?.map((item, index) => {
    dataSource.push({
      stt: index + 1,
      id: item.id,
      name: item,
      link: item,
    });
  });

  const handleUpdateImage = () => {
    const data = {
      file_id: imageId,
    };

    updateImageVisionMutation.mutate(data, {
      onSuccess: (response) => {
        toast.success('Cập Nhật Hình Ảnh Thành Công');
        setImageUrl(null);
        setImageId(null);
      },
      onError: (error) => {
        toast.error('Cập Nhật Hình Ảnh Thất Bại');
      },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRowKeys);
      setId(selectedRowKeys);
    },
  };
  const renderExtra = <></>;
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      key: 'stt',
      width: '60px',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (value) => (
        <div
          onClick={() => {
            setId(value.id);
            setIsModalEdit(true);
          }}
          className="text-blue-500 cursor-pointer"
        >
          {value?.name}
        </div>
      ),
    },
    {
      title: 'Đường dẫn',
      dataIndex: 'link',
      key: 'link',
      align: 'center',
      render: (value) => (
        <Link to={value?.link} className="text-blue-500">
          {value?.link}
        </Link>
      ),
    },
  ];
  return (
    <>
      <CreateModal
        isModalOpen={isModalCreate}
        setIsModalOpen={setIsModalCreate}
        refetch={refetch}
      />

      {id && isModalEdit && (
        <EditModal
          isModalOpen={isModalEdit}
          setIsModalOpen={setIsModalEdit}
          refetch={refetch}
          id={id}
        />
      )}

      <div className="title text-blue-500">HOME PAGE - TẦM NHÌN & SỨ MỆNH</div>

      <div className="w-fit border rounded my-5 py-5 pb-5 hover:shadow">
        <div className=" -translate-x-12 flex">
          <UploadImage
            setImageId={setImageId}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
          />

          {!imageUrl && (
            <div className="ml-3 p-[1px] border border-collapse">
              <img
                src={ImageVision?.data?.file_id?.path}
                width={200}
                height={150}
              />
            </div>
          )}
        </div>

        <div className="-translate-x-12 mt-5" onClick={handleUpdateImage}>
          <SaveButton />
        </div>
      </div>

      <div className=" border rounded p-5 hover:shadow">
        <div className="mb-5 flex">
          <Button
            className="bg-blue-500 text-white mr-3 rounded"
            onClick={() => setIsModalCreate(true)}
          >
            TẠO MỚI
          </Button>
          <Button
            className="bg-yellow-500 text-white mr-3 rounded"
            disabled={selected?.length !== 1}
            onClick={() => setIsModalEdit(true)}
          >
            SỬA
          </Button>
          <DeleteButton
            selected={selected}
            refetch={refetch}
            deleteMutation={deleteVisionMenuMutation}
          />
        </div>

        <Table
          rowKey="id"
          rowSelection={{
            ...rowSelection,
          }}
          dataSource={dataSource}
          columns={columns}
          extra={renderExtra}
          bordered
        />
      </div>
    </>
  );
}
