import { useIntl } from 'react-intl';
import { PAYMENT_STATUS } from 'constants/status';
import {
  StyledSpace,
  StyledButton,
  StyledTypographyParagraph,
} from 'styles/overrides';

export const PaymentStatusAction = ({
  paymentStatus,
  onClickAction = () => {},
}) => {
  const intl = useIntl();

  if (
    paymentStatus !== PAYMENT_STATUS.PAID &&
    paymentStatus !== PAYMENT_STATUS.PENDING
  ) {
    return null;
  }

  return (
    <StyledSpace direction="vertical" style={{ alignItems: 'center' }}>
      {paymentStatus === PAYMENT_STATUS.PAID && (
        <StyledTypographyParagraph style={{ color: 'var(--primary)' }}>
          {intl.formatMessage({ id: 'views.orders.table.true' })}
        </StyledTypographyParagraph>
      )}

      <StyledButton
        onClick={() =>
          onClickAction(
            paymentStatus === PAYMENT_STATUS.PAID
              ? PAYMENT_STATUS.PENDING
              : PAYMENT_STATUS.PAID,
          )
        }
      >
        {intl.formatMessage({
          id: `views.orders.table.${
            paymentStatus === PAYMENT_STATUS.PAID ? 'deny' : 'confirm'
          }`,
        })}
      </StyledButton>
    </StyledSpace>
  );
};
