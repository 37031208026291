import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { CardWrapper } from 'components/layout';
import { StyledButton } from 'styles/overrides';
import { CloseIcon } from 'assets/icons';

const StyledPaymentItem = styled(CardWrapper)`
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #b5b6ba;
  height: 100%;
  cursor: pointer;
  position: relative;

  @media (max-width: 575px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  .payment-item {
    display: flex;
    align-items: center;

    @media (max-width: 575px) {
      flex-direction: column;
    }

    .image {
      position: relative;
      max-width: 89px;
      width: 100%;

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .info {
      padding: 0 20px;
      color: var(--gray900);
      font-size: 16px;
      flex-grow: 1;

      @media (max-width: 575px) {
        padding: 0;
        align-self: flex-start;
      }

      &-item {
        &-label {
          display: none;

          &.show {
            display: inline-block;
            margin-right: 4px;
          }

          @media (max-width: 575px) {
            display: inline-block;
            margin-right: 4px;
            font-weight: 700;
          }
        }
      }

      .account-name {
        @media (min-width: 576px) {
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      .bank-branch {
        @media (min-width: 576px) {
          font-size: 12px;
          color: var(--gray500);
        }
      }
    }
  }

  .delete-btn {
    position: absolute;
    top: 4px;
    right: 4px;
  }
`;

export const PaymentItem = ({
  item,
  onClickDelete = () => {},
  onClickEdit = () => {},
  isShowActions = true,
}) => {
  const intl = useIntl();
  const { bankAccountOwner, bankAccountNumber, bankBranch, bank } = item;

  return (
    <StyledPaymentItem>
      <div className="payment-item" onClick={onClickEdit}>
        <div className="image">
          <img src={bank?.logoUrl} alt="" />
        </div>

        <div className="info">
          <div className="account-name info-item">
            <span className="info-item-label">
              {intl.formatMessage({ id: 'components.bank.accountOwner' })}:
            </span>
            <span>{bankAccountOwner}</span>
          </div>

          <div className="bank-name info-item">
            <span className="info-item-label">
              {intl.formatMessage({ id: 'components.bank.accountNumber' })}:
            </span>
            <span>{bankAccountNumber}</span>
          </div>

          <div className="bank-name info-item">
            <span className="info-item-label">
              {intl.formatMessage({ id: 'components.bank.name' })}:
            </span>
            <span>
              {bank?.name} - {bank?.fullName}
            </span>
          </div>
          <div className="bank-branch">
            <span className="info-item-label show">
              {intl.formatMessage({ id: 'components.bank.branch' })}:
            </span>
            <span>{bankBranch}</span>
          </div>
        </div>
      </div>

      {isShowActions && (
        <StyledButton
          icon={<CloseIcon />}
          className="delete-btn"
          onClick={onClickDelete}
        />
      )}
    </StyledPaymentItem>
  );
};
