import { useBankSettings } from 'api/bankSettingApi';
import _ from 'lodash';
import { PaymentList } from 'components/payment';

export const OrderBankSetting = ({ banks, shopId }) => {
  const { data = [] } = useBankSettings({
    params: { shopId },
    options: {
      enabled: _.isNil(banks),
    },
  });

  return <PaymentList banks={banks ?? data} isShowActions={false} />;
};
