import { useIntl } from 'react-intl';
import { Select } from 'antd';

import { DownloadIcon } from 'assets/icons';
import { DEFAULT_PAGINATION } from 'constants/common';
import { FilterForm } from 'components/form';
import {
  StyledFormItem,
  StyledSelect,
  StyledButton,
  StyledSpace,
} from 'styles/overrides';
import { normalizeSearchData, normalizeSubmitSearchData } from 'utils/common';
import { ShopSelector } from 'components/selector';

export const SaleRankReportFilter = ({
  onSubmit,
  initialValues,
  years = [],
}) => {
  const intl = useIntl();

  const handleSubmit = (values) => {
    onSubmit(
      normalizeSubmitSearchData({
        ...initialValues,
        ...values,
        page: DEFAULT_PAGINATION.PAGE,
      }),
    );
  };

  return (
    <FilterForm
      onFinish={handleSubmit}
      initialValues={normalizeSearchData(initialValues)}
      extraActions={
        <StyledButton icon={<DownloadIcon />}>
          {intl.formatMessage({ id: 'common.btn.downloadExcel' })}
        </StyledButton>
      }
    >
      <StyledSpace size={16}>
        <StyledFormItem name="yearSaleRank">
          <StyledSelect
            $customType="filter"
            placeholder={intl.formatMessage({
              id: 'views.dashboard.form.placeholder.yearSaleRank',
            })}
          >
            {years.map((year) => (
              <Select.Option key={year} value={year}>
                {year}
              </Select.Option>
            ))}
          </StyledSelect>
        </StyledFormItem>

        <StyledFormItem name="shopId">
          <ShopSelector
            placeholder={intl.formatMessage({
              id: 'common.placeholder.shopId',
            })}
            $customType="filter"
            allowClear
          />
        </StyledFormItem>
      </StyledSpace>
    </FilterForm>
  );
};
