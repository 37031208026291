import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import flatten from 'flat';
import moment from 'moment';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useSelector } from 'react-redux';

import THEME from 'config/themes';
import AppLocale from 'config/translations';
import { validateMessages } from 'constants/common';
import { ROLE } from 'constants/roles';
import { PATH_NAME } from 'constants/routes';
import GlobalStyle from 'styles/globalStyle';
import { PrivateRoute } from 'components';
import IndexLayout from './layout/IndexLayout';
import DashboardLayout from './layout/DashboardLayout';
import AuthLayout from './layout/AuthLayout';
import Login from './views/auth/Login';
import Homepage from './views/homepage/Homepage';
import Dashboard from './views/dashboard/Dashboard';
import Orders from './views/orders/Orders';
import ViewOrder from './views/orders/ViewOrder';
import Products from './views/products/Products';
import Customers from './views/customers/Customers';
import Users from './views/users/Users';
import Logs from './views/logs/Logs';
import Settings from './views/settings/Settings';
import { NotFound } from './components/layout/NotFound';
import ViewDashboard from 'views/dashboard/ViewDashboard';
import Profile from 'views/profile/Profile';
import Guide from 'views/guide/Guide';
import CreateGuide from 'views/guide/CreateGuide';
import UpdateGuide from 'views/guide/UpdateGuide';
import Introduce from 'views/introduce/Introduce';
import CreateIntroduce from 'views/introduce/CreateIntroduce';
import UpdateIntroduce from 'views/introduce/UpdateIntroduce';
import Service from 'views/service/Service';
import CreateService from 'views/service/CreateService';
import UpdateService from 'views/service/UpdateService';
import News from 'views/news/News';
import CreateNews from 'views/news/CreateNews';
import UpdateNews from 'views/news/UpdateNews';
import Action from 'views/action/Action';
import CreateAction from 'views/action/CreateAction';
import UpdateAction from 'views/action/UpdateAction';
import ImageLibrary from 'views/images/ImageLibrary';
import VideoLibrary from 'views/videos/VideoLibrary';
import QuestionAnswer from 'views/QA/QuestionAnswer';
import PriceService from 'views/guide/prices/PriceService';
import CreatePriceService from 'views/guide/prices/CreatePriceService';
import UpdatePriceService from 'views/guide/prices/UpdatePriceService';
import CreateQA from 'views/QA/CreateQA';
import UpdateQA from 'views/QA/UpdateQA';
import CreateImageLibrary from 'views/images/CreateImageLibrary';
import UpdateImageLibrary from 'views/images/UpdateImageLibrary';
import CreateVideoLibrary from 'views/videos/CreateVideoLibrary';
import UpdateVideoLibrary from 'views/videos/UpdateVideoLibrary';
import Vision from 'views/vision/Vision';
import Health from 'views/health/Health';
import HealthList from 'views/health/HealthList';
import CreateHealthList from 'views/health/CreateHealthList';
import UpdateHealthList from 'views/health/UpdateHealthList';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const locale = useSelector((state) => state.app.currentLocale);

  moment.locale(locale);

  return (
    <ConfigProvider
      locale={AppLocale[locale].antd}
      form={{ validateMessages: validateMessages[locale], requiredMark: false }}
      // componentSize="small"
    >
      <IntlProvider
        locale={locale}
        messages={flatten(AppLocale[locale].messages)}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={THEME}>
            <GlobalStyle />

            <Routes>
              <Route element={<IndexLayout />}>
                <Route path={PATH_NAME.ROOT} element={<Homepage />} />
                <Route path={PATH_NAME.ROOT} element={<Profile />} />
                <Route
                  path={PATH_NAME.USER}
                  element={<Profile isVerifyOTP />}
                />
              </Route>

              <Route element={<PrivateRoute />}>
                <Route path={PATH_NAME.ROOT} element={<DashboardLayout />}>
                  <Route index element={<Navigate to={PATH_NAME.ROOT} />} />

                  <Route path={PATH_NAME.GUIDE}>
                    <Route path="bang-gia-dich-vu" element={<PriceService />} />
                    <Route path="bang-gia-dich-vu/create" element={<CreatePriceService />} />
                    <Route path="bang-gia-dich-vu/edit/:slugDetail" element={<UpdatePriceService />} />

                    <Route path=":slug" element={<Guide />} />
                    <Route path="create/:slug" element={<CreateGuide />} />
                    <Route path="edit/:slugDetail" element={<UpdateGuide />} />
                  </Route>

                  <Route path={PATH_NAME.INTRODUCE}>
                    <Route path=":slug" element={<Introduce />} />
                    <Route path="create/:slug" element={<CreateIntroduce />} />
                    <Route path="edit/:slugDetail" element={<UpdateIntroduce />} />
                  </Route>

                  <Route path={PATH_NAME.SERVICE}>
                    <Route path="kham-suc-khoe" element={<Health />} />
                    <Route path="kham-suc-khoe/:slug" element={<HealthList />} />
                    <Route path="kham-suc-khoe/:slug/create" element={<CreateHealthList />} />
                    <Route path="kham-suc-khoe/:slug/edit/:slugDetail" element={<UpdateHealthList />} />

                    <Route path=":slug" element={<Service />} />
                    <Route path="create/:slug" element={<CreateService />} />
                    <Route path="edit/:slugDetail" element={<UpdateService />} />
                  </Route>

                  <Route path={PATH_NAME.NEWS}>
                    <Route path="thu-vien-anh" element={<ImageLibrary />} />

                    <Route path="thu-vien-video" element={<VideoLibrary />} />
                    <Route path="thu-vien-video/create" element={<CreateVideoLibrary />} />
                    <Route path="thu-vien-video/edit/:id" element={<UpdateVideoLibrary />} />

                    <Route path=":slug" element={<News />} />
                    <Route path="create/:slug" element={<CreateNews />} />
                    <Route path="edit/:slugDetail" element={<UpdateNews />} />
                  </Route>

                  <Route path={PATH_NAME.ACTION}>
                    <Route path=":slug" element={<Action />} />
                    <Route path="create/:slug" element={<CreateAction />} />
                    <Route path="edit/:slugDetail" element={<UpdateAction />} />
                  </Route>

                  <Route path={PATH_NAME.QA}>
                    <Route index element={<QuestionAnswer />} />
                    <Route path="create" element={<CreateQA />} />
                    <Route path="edit/:id" element={<UpdateQA />} />
                  </Route>

                  <Route path={PATH_NAME.VISION_MISSION}>
                    <Route index element={<Vision />} />
                  </Route>

                    {/* ------------ */}


                  <Route path={PATH_NAME.DASHBOARD}>
                    <Route index element={<Dashboard />} />
                    <Route path="detail" element={<ViewDashboard />} />
                  </Route>

                  <Route path={PATH_NAME.ORDERS}>
                    <Route index element={<Orders />} />
                    <Route path=":id" element={<ViewOrder />} />
                    
                  </Route>

                  <Route path={PATH_NAME.PRODUCTS}>
                    <Route index element={<Products />} />
                  </Route>

                  <Route path={PATH_NAME.CUSTOMERS}>
                    <Route index element={<Customers />} />
                  </Route>

                  <Route path={PATH_NAME.USERS}>
                    <Route index element={<Users />} />
                  </Route>

                  <Route path={PATH_NAME.LOGS}>
                    <Route index element={<Logs />} />
                  </Route>

                  <Route path={PATH_NAME.SETTINGS}>
                    <Route index element={<Settings />} />
                  </Route>

                  {/* <Route path={PATH_NAME.SHOPS}>
                    <Route index element={<Shops />} />
                  </Route> */}

                  <Route path="*" element={<NotFound />} />
                </Route>
              </Route>

              <Route element={<AuthLayout />}>
                <Route path={PATH_NAME.LOGIN} element={<Login />} />
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </ThemeProvider>

          <ReactQueryDevtools />
        </QueryClientProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;
