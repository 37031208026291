import { Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useCreateCategory } from 'api/healthApi';
import SaveButton from 'components/button/SaveButton';
import NameForm from 'components/formItem/NameForm';
import UploadImage from 'components/uploadImage/UploadImage';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

export default function CreateNewCategory({
  isModalOpen,
  setIsModalOpen,
  refetch,
}) {
  const [imageId, setImageId] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [name, setName] = useState();
  const [click, setClick] = useState(false);

  const useCreateCategoryMutation = useCreateCategory();

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setName(null);
    setImageUrl(null);
    setClick(false);
  };

  const onFinish = (values) => {
    const data = {
      thumbnail_vi: imageId,
      name_vi : name
    };
    useCreateCategoryMutation.mutate(
      { ...data },
      {
        onSuccess: (response) => {
          toast.success('Thêm Mới Thành Công');
          refetch();
          setIsModalOpen(false);
          setImageUrl(null);
          setName(null);
          setClick(false);
        },
        onError: (error) => {
          toast.error('Thêm Mới Thất Bại');
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Modal
      className="mt-[150px]"
      title="TẠO MỚI"
      width={1000}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <Form
        size="large"
        name="wrap"
        labelCol={{
          flex: '100px',
        }}
        labelWrap
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div>
          <p>Tên :</p>
          <Input
            allowClear
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="h-10"
          />
          {click && !name && (
            <div className="text-red-500">Vui lòng nhập tên *</div>
          )}
        </div>

        <div className=" -translate-x-[100px] mt-5">
          <UploadImage
            setImageId={setImageId}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
          />
        </div>

        <div className=" relative mt-5 mb-8 border-t border-collapse">
          <div
            className=" absolute top-0 right-0 pt-2"
            onClick={() => setClick(true)}
          >
            <SaveButton />
          </div>
        </div>
      </Form>
    </Modal>
  );
}
