import { Button, Form, Modal } from 'antd';
import { useCreateImage } from 'api/imageLibraryApi';
import NameForm from 'components/formItem/NameForm';
import UploadImage from 'components/uploadImage/UploadImage';
import React, { useState, memo } from 'react';
import { toast } from 'react-toastify';

function CreateImageLibrary(props) {
  const [imageId, setImageId] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [ disableButton, setDisableButton ] = useState(true);

  const { modalCreateImage, setModalCreateImage, refetch } = props;

  const useCreateImageMutation = useCreateImage();
  const onFinish = (values) => {
    setDisableButton(false)
    const data = {
      file_id: imageId,
      ...values,
    };
    useCreateImageMutation.mutate(data, {
      onSuccess: (response) => {
        toast.success('Thêm Mới Thành Công');
        refetch();
      },
      onError: (error) => {
        if (!imageId) {
          toast.error('Vui Lòng Thêm Hình Ảnh');
        } else {
          toast.error('Thêm Mới Thất Bại');
        }
        setDisableButton(true)
      },
    });
    setModalCreateImage(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <Modal
        footer={null}
        title="Thêm Hình Ảnh"
        open={modalCreateImage}
        onCancel={() => setModalCreateImage(false)}
      >
        <div>
          <Form
            className=" relative"
            size="large"
            name="wrap"
            labelCol={{
              flex: '100px',
            }}
            labelWrap
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <NameForm label="Tiêu đề :" name="title_vi" />

            <UploadImage
              setImageId={setImageId}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
            />
            <div className="border-t border-collapse h-[40px] mt-3">
              <Button
                htmlType="submit"
                disable={disableButton}
                className=" absolute right-2 mt-3 bg-blue-500 text-white rounded"
              >
                LƯU
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default memo(CreateImageLibrary);
