import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const shopApi = {
  getAll(params) {
    return axiosClient.get('/shops', { params });
  },
  create(data) {
    return axiosClient.post('/shops', data);
  },
  update({ id, ...data }) {
    return axiosClient.put(`/shops/${id}`, data);
  },
};

export const useShops = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['shops', params],
    queryFn: () => shopApi.getAll(params),
    ...options,
  });
};

export const useCreateShop = () => {
  return useMutation(shopApi.create);
};

export const useUpdateShop = () => {
  return useMutation(shopApi.update);
};
