/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Table } from 'components/table';
import { OrderStatus, SmsStatus } from 'components/status';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import {
  calcIndexByOrder,
  removeFalsyKeys,
  formatTime,
  formatAddress,
  formatCurrency,
} from 'utils';
import { StyledActions, StyledButton } from 'styles/overrides';
import {
  useOrders,
  useImportOrder,
  useDownloadCsvTemplate,
  useExportExcel,
  useUpdateOrderPayment,
  useUpdateNoteAdmin,
} from 'api/orderApi';
import { OrderFormDrawer } from './OrderFormDrawer';
import { OrderFilterSearchForm } from './OrderFilterSearchForm';
import { Upload } from 'components/upload';
import { DownloadTemplate } from 'components/download-template/DownloadTemplate';
import { ExportExcel } from 'components/export/ExportExcel';
import { ResendSMS } from './ResendSMS';
import { Input, Modal } from 'antd';
import { Notification } from 'components';
import { ROLE } from 'constants/roles';
import { Permission } from 'components/permission/Permission';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/slices/authSlice';
import { PaymentStatusAction } from './PaymentStatusAction';

export const OrderTable = () => {
  const intl = useIntl();
  const currentUser = useSelector(getCurrentUser);

  const useCustomSearchParams_2 = () => {
    const search_2 = useCustomSearchParams();
    const order_limit = 100;
    search_2.limit = order_limit;
    search_2[0].limit = order_limit;
    // console.log('search_2', search_2);
    return search_2;
  };
  const [search, setSearch] = useCustomSearchParams_2();
  const { data: product, isLoading, refetch } = useOrders({ params: search });
  const importOrderMutation = useImportOrder();
  const downloadCsvTemplateMutation = useDownloadCsvTemplate();
  const exportExcelMutation = useExportExcel(search);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const updateOrderPaymentStatusMutation = useUpdateOrderPayment();
  const updateOrderNoteAdmin = useUpdateNoteAdmin();

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => {
        return calcIndexByOrder({
          index,
          page: search.page,
          limit: search.limit,
        });
      },
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.code' }),
      className: 'withColor',
      render: ({ id, code }) => <Link to={`${id}`}>{code}</Link>,
    },
    ...([ROLE.SUPPER_ADMIN, ROLE.ADMIN].includes(currentUser?.role)
      ? [
          {
            title: intl.formatMessage({
              id: 'views.orders.table.confirmPayment',
            }),
            className: 'withColor',
            render: ({ id, payment_status }) => {
              return (
                <PaymentStatusAction
                  paymentStatus={payment_status}
                  onClickAction={(newPaymentStatus) =>
                    handleUpdatePaymentStatus(id, newPaymentStatus)
                  }
                />
              );
            },
          },
        ]
      : []),
    {
      title: intl.formatMessage({ id: 'views.orders.table.shopName' }),
      dataIndex: ['shop', 'name'],
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.fullName' }),
      dataIndex: ['orderAddress', 'fullName'],
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.phone' }),
      dataIndex: ['orderAddress', 'phone'],
    },
    {
      title: intl.formatMessage({ id: 'common.table.livestreamDate' }),
      dataIndex: 'livestreamDate',
      render: (value) => formatTime(value),
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.address' }),
      dataIndex: 'orderAddress',
      render: (value) => formatAddress(value),
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.productPrice' }),
      dataIndex: 'subTotal',
      render: (value) => formatCurrency(value),
    },
    {
      title: intl.formatMessage({ id: 'common.table.status' }),
      dataIndex: 'status',
      render: (value) => <OrderStatus status={value} />,
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.smsStatus' }),
      dataIndex: 'sms_status',
      render: (value) => <SmsStatus status={value} />,
    },
    {
      title: intl.formatMessage({ id: 'views.orders.table.noteAdmin' }),
      dataIndex: 'noteAdmin',
      render: (value, record) => {
        return (
          <Input
            type="text"
            defaultValue={value}
            onPressEnter={(e) => {
              if ([ROLE.SUPPER_ADMIN, ROLE.ADMIN].includes(currentUser?.role)) {
                handleInputNoteAdmin(e, record.id);
              }
            }}
          />
        );
      },
    },
  ];

  const handleUpdatePaymentStatus = (id, status) => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.confirmUpdateTitle' }),
      onOk() {
        updateOrderPaymentStatusMutation.mutate(
          {
            order_id: id,
            payment_status: status,
          },
          {
            onSuccess() {
              refetch();
            },
            onError() {
              Notification(
                'error',
                intl.formatMessage({ id: 'message.commonError' }),
              );
            },
          },
        );
      },
      okText: intl.formatMessage({ id: 'common.btn.yes' }),
      okButtonProps: {
        disabled: updateOrderPaymentStatusMutation.isLoading,
      },
    });
  };

  const handleChangeTable = ({ current: page, pageSize: limit }) => {
    setSearch(removeFalsyKeys({ ...search, page, limit }));
  };

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
  };

  const handleInputNoteAdmin = (e, id) => {
    updateOrderNoteAdmin.mutate(
      {
        order_id: id,
        noteAdmin: e.target.value,
      },
      {
        onSuccess() {
          refetch();
          Notification(
            'success',
            intl.formatMessage({ id: 'message.noteAdminSuccess' }),
          );
        },
        onError() {
          Notification(
            'error',
            intl.formatMessage({ id: 'message.commonError' }),
          );
        },
      },
    );
  };

  return (
    <>
      <OrderFilterSearchForm
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />

      <StyledActions size={24}>
        <Permission roles={[ROLE.SUPPER_ADMIN, ROLE.ADMIN]}>
          <ResendSMS
            onSuccess={() => {
              refetch();
              setSelectedRowKeys([]);
              setSelectedRows([]);
            }}
            selectedRows={selectedRows}
          />
        </Permission>

        <Permission roles={[ROLE.SUPPER_ADMIN, ROLE.ADMIN]}>
          <Upload mutation={importOrderMutation} onSuccess={refetch} />
        </Permission>

        <DownloadTemplate
          mutation={downloadCsvTemplateMutation}
          fileName="orders_sample.csv"
        />

        <ExportExcel mutation={exportExcelMutation} />

        <Permission roles={[ROLE.SUPPER_ADMIN, ROLE.ADMIN]}>
          <StyledButton size="large" type="primary" onClick={handleOpenDrawer}>
            {intl.formatMessage({ id: 'common.btn.addNew' })}
          </StyledButton>
        </Permission>
      </StyledActions>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedRows(newSelectedRows);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={product?.items || []}
        loading={isLoading || updateOrderPaymentStatusMutation.isLoading}
        pagination={{
          current: search?.page,
          pageSize: search?.limit,
          total: product?.total || 0,
        }}
        onChange={handleChangeTable}
      />

      <OrderFormDrawer
        open={isOpenDrawer}
        onClose={handleCloseDrawer}
        onSuccess={refetch}
      />
    </>
  );
};
