import axiosClient from './axiosClient';
import { useQuery, useMutation } from 'react-query';


export const introduceApi = {
  getMenu(params){
    return axiosClient.get('/introduce', {params})
  },
  getIntroduce(slug){
    return axiosClient.get(`/introduce/${slug}`)
  },
  getIntroduceList(slug,params) {
    return axiosClient.get(`/introduce/list/${slug}?page=${params.page}&page_size=${params.page_size}`);
  },
  create(data) {
    return axiosClient.post('/admin/introduce', data);
  },
  updateIntroduce(data) {
    return axiosClient.patch(`/admin/introduce/${data.slug}`, data.data);
  },
  delete(data) {
    return axiosClient.delete(`/admin/introduce/delete/multiple`,data);
  },
};

export const useIntroduceMenu = ({params}) => {
  return useQuery({
      queryKey: ['introduce', params],
      queryFn: () => introduceApi.getMenu(params),

  })
}

  export const useIntroduceList = ({ slug, params, options } = {}) => {
    return useQuery({
      queryKey: ['introduce-list', slug, params],
      queryFn: () => introduceApi.getIntroduceList(slug, params),
      ...options,
    });
  };
  export const useIntroduce = ({ slug, options } = {}) => {
    return useQuery({
      queryKey: ['introduce-detail', slug],
      queryFn: () => introduceApi.getIntroduce(slug),
      ...options,
    });
  };

  export const useCreateIntroduce = () => {
    return useMutation(introduceApi.create);
  };

  export const useUpdateIntroduce = () => {
    return useMutation(introduceApi.updateIntroduce);
  };

  export const useDeleteIntroduce = () => {
    return useMutation(introduceApi.delete);
  };