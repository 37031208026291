import { CloudUploadOutlined } from '@ant-design/icons';
import { useUploadImage } from 'api/imageUpload';
import React, { memo, useState } from 'react';

function UpLoadFile({ setFileId, fileUrl, setFileUrl }) {
  const [file, setFile] = useState('');
  const useImageUploadMutation = useUploadImage();

  function handleFile(e) {
    setFile(e.target.files[0]);
  }
  function handleApi() {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      useImageUploadMutation.mutate(formData, {
        onSuccess: (res) => {
          setFileId(res.data.id);
          setFileUrl(res.data.path);
        },
        onError: (error) => {
          console.log('error', error);
        },
      });
      setFile(null);
    }
  }

  return (
    <div className="flex ml-[100px] mt-2 mb-5">
      <label name="file" htmlFor="file" onMouseLeave={handleApi} className=' px-10 py-5 w-[300px] rounded border border-dashed border-gray-300 text-center cursor-pointer text-[#08ca]'>
        <CloudUploadOutlined style={{ fontSize: '36px', color: '#08ca' }} />
        <p>Chọn File Pdf </p>
      </label>
      {fileUrl && (
          <div className="ml-2 mt-2 text-blue-500">
            <a href={fileUrl} target="_blank">
              {fileUrl}
            </a>
          </div>
        )}
      <div className='hidden'>
        <input
          id='file'
          type="file"
          name="file"
          className="ml-2"
          onChange={handleFile}
        ></input>
        <p></p>
        {fileUrl && (
          <div className="ml-2 mt-2 text-blue-500">
            <a href={fileUrl} target="_blank">
              {fileUrl}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(UpLoadFile);
