import { useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const orderItemApi = {
  updateName({ id, ...data }) {
    return axiosClient.patch(`/order-items/${id}/update-name`, data);
  },
};
export const useUpdateOrderItemName = () => {
  return useMutation(orderItemApi.updateName);
};
