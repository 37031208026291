/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { getCurrentUser } from 'store/slices/authSlice';
import { StyledSider, StyledSiderUserInfo } from 'styles/overrides';
import { UserInfo } from 'components/user/UserInfo';
import { useMenu } from 'api/menuApi';

import { SiderMenu } from './SiderMenu';
import {
  findMatchedMenuItemByPathname,
  findRelatedMatchedMenuItemByPathname,
  findRelatedMenuItemsByMatchedMenuItem,
} from '../utils/handleMenuItems';
import { setBreadcrumbs } from 'store/slices/layoutSlice';

export const Sider = ({ collapsed = false, items = [] }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector(getCurrentUser);
  const [selectedKeys, setSelectedKeys] = useState([]);


  function getItem(label, key, children) { //getItem of AntD Menu
    return {
      label,
      key,
      children
    };
  }
  const normalizeMenuSubItem = useCallback((item, parentSlug) => { //make new label as next/Link for new Menu AntD
    if (item.slug_vi) { //menu lvl2
      const toUpperCaseLabel = item.name_vi.toUpperCase()
      const newLabel = <Link to={`${parentSlug}/${item.slug_vi}`}>{toUpperCaseLabel}</Link>;
      return getItem(newLabel, item.slug_vi)
    }
    return item;
  }, []);

  const normalizeSubMenuItems = useCallback((items = [], parentSlug) => { //make new label for entire array
    return items.map((item) => {
      const newItem = normalizeMenuSubItem(item, parentSlug)
      return newItem
    });
  }, []);
  const hanldeMenuItem = ((items = []) => {
    return items.map((item) => {
      if(item.children.length === 0) //if menu has no submenu, we would like to make it a Link
        return getItem(<Link to={item.slug}>{item.name}</Link>)
      return getItem(item.name, item.slug, normalizeSubMenuItems(item.children, item.slug)) //if yes, make it a label
    })
  })
  const menuItems = useMemo(() =>
  normalizeSubMenuItems(
      items.map((item) => ({
        ...item,
        label: intl.formatMessage({ id: `layout.sider.${item.key}` }),
      })),
    ),
  );

  useEffect(() => {
    let matchedMenuItem = findMatchedMenuItemByPathname(
      menuItems,
      location.pathname,
    );

    if (!matchedMenuItem) {
      matchedMenuItem = findRelatedMatchedMenuItemByPathname(
        menuItems,
        location.pathname,
      );
    }

    if (!matchedMenuItem) {
      return;
    }

    const matchedMenuItems = findRelatedMenuItemsByMatchedMenuItem(
      matchedMenuItem,
      menuItems,
    );

    const layoutData = matchedMenuItems.reduce(
      (obj, curr) => {
        obj.selectedKeys.push(curr.key);
        obj.breadcrumbs.push({
          key: curr.key,
          label: curr.text ?? curr.label,
          url: curr.url,
        });

        return obj;
      },
      { selectedKeys: [], breadcrumbs: [] },
    );

    dispatch(setBreadcrumbs(layoutData.breadcrumbs));

    setSelectedKeys(layoutData.selectedKeys);
  }, [location.pathname]);

  const addMenu = {
    label: <div className=' -translate-y-[18px]'><Link to='tam-nhin-su-menh' className='!text-[#bebebe] hover:!text-white'>TẦM NHÌN VÀ SỨ MỆNH</Link></div>
  }

  const {data = [], isLoading, isFetching} = useMenu({})
  if(isFetching || isLoading)
  return
   const menu = hanldeMenuItem(data.data).slice(1,7)
    menu.push(addMenu)

  return (
    <StyledSider trigger={null} collapsible collapsed={collapsed} >
      <StyledSiderUserInfo>
        <UserInfo
          isVertical
          fullName={currentUser.fullName}
          role={currentUser.role}
          isCollapsed={collapsed}
        />
      </StyledSiderUserInfo>

      <SiderMenu items={menu} selectedKeys={selectedKeys} />
    </StyledSider>
  );
};
