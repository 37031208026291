import axiosClient from './axiosClient';
import { useQuery, useMutation } from 'react-query';

export const imageUploadApi = {

  uploadImage(data) {
    return axiosClient.post('/admin/file', data);
  },

};

  export const useUploadImage = () => {
    return useMutation(imageUploadApi.uploadImage);
  };
