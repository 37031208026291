import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getBreadcrumbs } from 'store/slices/layoutSlice';
import { getFirstTitleFromBreadcrumbs } from 'utils/common';
import { ContentWrapper } from 'components/layout';
import { PaymentSetting } from './components/PaymentSetting';
import { NoteSetting } from './components/NoteSetting';
import { SettingFilterSearchForm } from './components/SettingFilterSearchForm';
import _ from 'lodash';

export default function Settings() {
  const currentBreadcrumbs = useSelector(getBreadcrumbs);
  const title = getFirstTitleFromBreadcrumbs(currentBreadcrumbs);
  const [shopId, setShopId] = useState(null);

  return (
    <ContentWrapper title={title} hasBorder>
      <SettingFilterSearchForm
        onSubmit={({ shopId }) => setShopId(shopId)}
        initialValues={{ shopId }}
      />

      {!_.isNil(shopId) && (
        <>
          <NoteSetting shopId={shopId} />

          <PaymentSetting shopId={shopId} />
        </>
      )}
    </ContentWrapper>
  );
}
