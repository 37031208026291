import { Form } from 'antd';
import React from 'react';
import TextArea from 'antd/lib/input/TextArea';

function DescriptionForm({ label, name }) {
  return (
    <>
      <Form.Item
        className='mt-5'
        label={label}
        name={name}
        rules={[{ required: true, message: `Vui lòng nhập ${label} ` }]}
      >
        <TextArea rows={5} />
      </Form.Item>
    </>
  );
}

export default DescriptionForm;
