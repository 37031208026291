import { Button } from 'antd'
import React from 'react'
import { toast } from 'react-toastify';

function DeleteButton({selected, refetch, deleteMutation}) {

  const handleDelete = () => {
    deleteMutation.mutate({data: {ids : selected}}, {
      onSuccess: () => {
        toast.success(`Xóa Thành Công`);
        refetch();
      },
      onError: () => {
        toast.error(`Xóa Thất Bại`);
      },
    });
  };

  return (
    <div>
        <Button disabled={!selected.length} className='bg-red-500 text-white rounded' onClick={handleDelete}>XÓA</Button>
    </div>
  )
}

export default DeleteButton