import styled from 'styled-components';
import { StyledLayoutFooter } from 'styles/overrides';

const StyledFooterTop = styled.div`
  color: var(--black);

  @media (max-width: 575px) {
    font-size: 12px;
  }
`;

export const Footer = (props) => {
  return (
    <StyledLayoutFooter {...props}>
      <StyledFooterTop>
        <strong>TRUNG TÂM Y TẾ VIETSOVPETRO</strong>
        <p>Địa chỉ: Số 02 Pasteur P.7 TP. Vũng Tàu.</p>
        <p>Hỗ trợ: 0888.91.72.72</p>
        <p>Liên hệ: cskhytevsp.mc@vietsov.com.vn</p>
      </StyledFooterTop>
    </StyledLayoutFooter>
  );
};
