import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import './quill.css'
import ImageResize from 'quill-image-resize-module-react';



const Quill = ReactQuill.Quill;
Quill.register('modules/imageResize', ImageResize);

var Size = Quill.import('formats/size');
var Font = Quill.import("formats/font");
Font.whitelist = [
    "arial",
    // "times-new-roman",
    // 'Ubuntu',
    // 'Raleway',
    // 'Roboto',
    // 'mirza'
  ];

Quill.register(Font, true);

export const modules = {
  toolbar: [
    [{ font: Font.whitelist }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
    ["blockquote"],
    [{ align: [] }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],  
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
  }
};

export const formats = [
  "font",
  "size",
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "link",
  "image",
  "blockquote",
  "align",
  "list",
  'bullet',
];
