import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';

import DeleteButton from 'components/button/DeleteButton';
import { formatTime } from 'utils';
import { useDeleteQA, useQA } from 'api/QA';
import CreateNewButton from 'components/button/CreateNewButton';
import EditButton from 'components/button/EditButton';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';

const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
    key: 'stt',
    width: '60px',
  },
  {
    title: 'Chủ Đề ',
    dataIndex: 'topic',
    key: 'topic',
    align: 'center',
    render: (value) => (
      <Link to={`edit/${value?.id}`} className="text-blue-500">
        {value?.topic_vi}
      </Link>
    ),
  },
  {
    title: 'Ngày Cập Nhật',
    dataIndex: 'update_at',
    key: 'update_at',
    align: 'center',
    render: (value) => formatTime(value, 'DD/MM/YYYY'),
  },
  {
    title: 'Ngày Tạo',
    dataIndex: 'create_at',
    key: 'create_at',
    align: 'center',
    render: (value) => formatTime(value, 'DD/MM/YYYY'),
  },
];

export default function QuestionAnswer() {
  const [id, setId] = useState();
  const [selected, setSelected] = useState([]);

  const [search, setSearch] = useCustomSearchParams();
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };

  const { data, refetch } = useQA( argument );
  const deleteQAMutation = useDeleteQA();

  const dataSource = [];
  data?.data?.map((item, index) => {
    dataSource.push({
      id: item.id,
      key: index,
      stt: index + 1,
      topic: item,
      update_at: item.updated_at,
      create_at: item.created_at,
    });
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setId(selectedRows[0]?.id);
      setSelected(selectedRowKeys);
    },
  };

  const handleChange = ({ current, pageSize }) =>
  setSearch({ ...search, page: current, page_size: pageSize });
  const renderExtra = <></>;
  return (
    <div>

      <div className="my-5 flex">
        <CreateNewButton slug="create" />
        <EditButton selected={selected} slug={`edit/${id}`} />
        <DeleteButton
          selected={selected}
          refetch={refetch}
          deleteMutation={deleteQAMutation}
        />
      </div>

      <Table
        bordered
        rowKey="id"
        rowSelection={{
          ...rowSelection,
        }}
        dataSource={dataSource}
        columns={columns}
        onChange={handleChange}
        extra={renderExtra}
        pagination={{
          total: data?.meta?.total ?? 0,
          className: 'mr-5',
        }}
      />
    </div>
  );
}
