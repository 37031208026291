import { CloudUploadOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import { useUploadImage } from 'api/imageUpload';
import React, { memo, useState } from 'react';

function SwitchBanner({
  switchBanner,
  setSwitchBanner,
  setImageBannerId,
  imageBannerUrl,
  setImageBannerUrl,
}) {
  const [imageBanner, setImageBanner] = useState('');

  const useImageUploadMutation = useUploadImage();

  function handleImageBanner(e) {
    setImageBanner(e.target.files[0]);
  }
  function handleApiImageBanner() {
    if (imageBanner) {
      const formDataBanner = new FormData();
      formDataBanner.append('file', imageBanner);
      useImageUploadMutation.mutate(formDataBanner, {
        onSuccess: (res) => {
          setImageBannerId(res.data.id);
          setImageBannerUrl(res.data.path);
        },
        onError: (error) => {
          console.log('error', error);
        },
      });
      setImageBanner(null);
    }
  }
  return (
    <div className="ml-[100px] mb-5">
      <p className="mb-2">Hiện thị trên banner : </p>
      <Switch
        checked={switchBanner ? true : false}
        onChange={() => setSwitchBanner(!switchBanner)}
        className={
          switchBanner ? 'bg-blue-500 duration-300' : 'bg-gray-500 duration-300'
        }
      />
      {(switchBanner && (
        <div className="flex mt-2 mb-5">
          <label
            name="file"
            htmlFor="imageBanner"
            // onChange={handleImageBanner}
            onMouseLeave={handleApiImageBanner}
            className="flex justify-center bg-white px-10 py-5 w-[300px] rounded border border-dashed border-gray-300 text-center cursor-pointer text-[#08ca]"
          >
            <div className="my-auto">
              <CloudUploadOutlined
                style={{ fontSize: '36px', color: '#08ca' }}
              />
              <p>Chọn Hình Ảnh </p>
            </div>
          </label>
          {imageBannerUrl && (
            <div className="max-w-[160px] border border-gray-200 ml-2 shadow p-[1px] my-auto">
              <img src={imageBannerUrl}></img>
            </div>
          )}
          <div className="hidden">
            <input
              id='imageBanner'
              type="file"
              name="file"
              className="ml-2 my-auto"
              onChange={handleImageBanner}
              // onMouseLeave={handleApiImageBanner}
            ></input>
            {/* {imageBannerUrl && (
              <img src={imageBannerUrl} className="h-[50px] ml-2" />
            )} */}
          </div>
        </div>
      )) ||
        null}
    </div>
  );
}

export default memo(SwitchBanner);
